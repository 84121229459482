<template>
  <v-container class="pa-0" fluid>
    <v-row align="start" justify="center" no-gutters>
      <v-col cols="12">
        <v-card :img="require('@/assets/images/carousel.png')" flat height="400" tile>
          <v-overlay absolute class="rounded-lg">
            <v-row align="center" class="h-100" justify="center" no-gutters>
              <v-col class="mt-auto" cols="12">
              <span class="text-h3 green--text accent-3">
                {{ $vuetify.lang.t('$vuetify.pages.contactUs.title') }}
              </span>
              </v-col>
              <v-col class="mb-auto" cols="10" md="6">
                <v-card-text class="text-start">
                <span class="text-body-1 white--text">
                {{ $vuetify.lang.t('$vuetify.pages.contactUs.subtitle') }}
                </span>
                </v-card-text>
              </v-col>
            </v-row>
          </v-overlay>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" xl="4">
        <v-card class="ma-5">
          <v-card-title>
            {{ $vuetify.lang.t('$vuetify.pages.contactUs.dropALine') }}
          </v-card-title>
          <v-card-text>
            <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
              <v-form @submit.prevent="handleSubmit(submit)">
                <validation-provider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.pages.contactUs.name')"
                                     rules="required|min:2|max:30">
                  <v-text-field v-model="contact.name" :error-messages="errors"
                                :label="$vuetify.lang.t('$vuetify.pages.contactUs.name')" outlined/>
                </validation-provider>
                <validation-provider v-slot="{ errors }" name="Email" rules="required|email">
                  <v-text-field v-model="contact.email" :error-messages="errors" label="Email" outlined/>
                </validation-provider>
                <validation-provider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.pages.contactUs.message')"
                                     rules="required|min:10|max:250">
                  <v-textarea v-model="contact.message" :error-messages="errors"
                              :label="$vuetify.lang.t('$vuetify.pages.contactUs.message')" outlined rows="5"/>
                </validation-provider>
                <v-btn color="secondary" type="submit">
                  {{ $vuetify.lang.t('$vuetify.pages.contactUs.contactBtn') }}
                </v-btn>
              </v-form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="ma-5">
          <v-card-title>{{ $vuetify.lang.t('$vuetify.pages.contactUs.headOfficeInfo') }}</v-card-title>
          <v-card-text class="text-start">
            <v-row v-for="(item, index) in officeInfo" :key="index" align="center" justify="center" no-gutters>
              <v-col cols="auto">
                <v-btn icon x-large>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                {{ item.text }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'KurccContactPage',
  components: {
    ValidationObserver
  },
  data () {
    return {
      contact: {
        name: null,
        email: null,
        message: null
      },
      officeInfo: [
        {
          icon: 'mdi-map-marker-outline',
          text: 'PO Box 23310, Safat 13094, Kuwait City, Kuwait'
        },
        {
          icon: 'mdi-phone-in-talk-outline',
          text: '123-4567890123'
        },
        {
          icon: 'mdi-instagram',
          text: 'Instagram:KFI'
        },
        {
          icon: 'mdi-web',
          text: 'websiteUrl'
        }
      ]
    }
  },
  methods: {
    submit () {
      console.log('submit contact')
    }
  }
}
</script>
