var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"img":require('@/assets/images/carousel.png'),"flat":"","height":"400","tile":""}},[_c('v-overlay',{staticClass:"rounded-lg",attrs:{"absolute":""}},[_c('v-row',{staticClass:"h-100",attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"mt-auto",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h3 green--text accent-3"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.pages.contactUs.title'))+" ")])]),_c('v-col',{staticClass:"mb-auto",attrs:{"cols":"10","md":"6"}},[_c('v-card-text',{staticClass:"text-start"},[_c('span',{staticClass:"text-body-1 white--text"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.pages.contactUs.subtitle'))+" ")])])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8","xl":"4"}},[_c('v-card',{staticClass:"ma-5"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.pages.contactUs.dropALine'))+" ")]),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.pages.contactUs.name'),"rules":"required|min:2|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.contactUs.name'),"outlined":""},model:{value:(_vm.contact.name),callback:function ($$v) {_vm.$set(_vm.contact, "name", $$v)},expression:"contact.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email","outlined":""},model:{value:(_vm.contact.email),callback:function ($$v) {_vm.$set(_vm.contact, "email", $$v)},expression:"contact.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.pages.contactUs.message'),"rules":"required|min:10|max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.contactUs.message'),"outlined":"","rows":"5"},model:{value:(_vm.contact.message),callback:function ($$v) {_vm.$set(_vm.contact, "message", $$v)},expression:"contact.message"}})]}}],null,true)}),_c('v-btn',{attrs:{"color":"secondary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.pages.contactUs.contactBtn'))+" ")])],1)]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"ma-5"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.pages.contactUs.headOfficeInfo')))]),_c('v-card-text',{staticClass:"text-start"},_vm._l((_vm.officeInfo),function(item,index){return _c('v-row',{key:index,attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","x-large":""}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)],1),_c('v-col',[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }